<template>
  <BasePopupModal :modal="modal" :overlay-color="'white'" :width="900">
    <div class="change-item-modal">
      <div class="change-item-modal--header font-weight-light">
        {{ $t(`modals.agents.${modalType}.title`) }}
      </div>
      <div class="change-item-modal--content">
        <div class="change-item-modal--content-input d-flex justify-center">
          <v-form class="change-item-modal--content-input-form">
            <v-container>
              <v-text-field v-model="formData.firstname" :label="'Prénom'" :rules="stringRules" outline> </v-text-field>
              <v-text-field v-model="formData.lastname" :label="'Nom'" :rules="stringRules" outline> </v-text-field>
              <v-select v-model="selectedProfile" :items="profiles" item-text="name" item-value="id" filled :label="'Profil'">
              </v-select>
              <v-select v-model="selectedEnterprise" :items="enterprises" item-text="name" item-value="id" filled :label="'Entreprise'">
              </v-select>
              <v-text-field v-model="convertedBadge" :label="'Badge'" :rules="badgeRules" outline> </v-text-field>

              <div v-if="isLimitedAccessEnabled" class="text-left red--text  text-caption">
                Par défaut, l'accès d'un agent est <b>Permanent</b> (sans limite horaire) <br />
                Sélectioner <b>Restreint</b> pour donner l'accès de 19h à 6h et les weekends et jours fériés <br />
                Sélectionner <b>Journée</b> pour donner l'accès de 6h à 19h ( hors weekend et jours fériés ). <br />

                <v-radio-group v-model="formData.isLimitedAccess" label="Type d'accès :" row>
                  <v-radio label="Permanent" :value="0"></v-radio>
                  <v-radio label="Restreint" :value="1"></v-radio>
                  <v-radio label="Journée" :value="2"></v-radio>
                </v-radio-group>
              </div>
            </v-container>
          </v-form>
        </div>
      </div>
      <div class="change-item-modal--buttons d-flex justify-end">
        <v-btn class="change-item-modal--buttons-cancel" outlined depressed @click="close">
          Annuler
        </v-btn>
        <v-btn class="change-item-modal--buttons-save" color="#38BB00" :disabled="isDisabled" dark depressed @click="submit">
          Valider
        </v-btn>
      </div>
    </div>
  </BasePopupModal>
</template>

<script>
import { get, find } from 'lodash';
import { mapGetters } from 'vuex';

import BasePopupModal from '@/components/modals/base/BasePopupModal';

import { formatValues } from '../../mixins/formatValues.mixin';

export default {
  name: 'ChangeAgentModal',
  components: {
    BasePopupModal
  },
  mixins: [formatValues],
  props: {
    modal: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      formData: {
        firstname: '',
        lastname: '',
        badge: '',
        isLimitedAccess: null
      },
      selectedProfile: null,
      selectedEnterprise: null,
      type: 1
    };
  },
  computed: {
    title() {
      return this.modal.payload.text.title;
    },
    oldValues() {
      return this.modal.payload.oldValues;
    },
    modalType() {
      const modal = ['edit', 'new'];
      return modal[this.type];
    },
    stringRules() {
      return [v => this.validateString(v) || 'Valeur invalide'];
    },
    badgeRules() {
      return [
        v => !!v.match(/^[a-fA-F0-9]*$/i) || "Caractères invalides",
        v => v.length < 16 || "Trop long",
        v => v.length > 0 || "Trop court"
      ];
    },
    isDisabled() {
      const disabled =
        this.validateString(this.formData.firstname) && this.validateString(this.formData.lastname) && this.selectedProfile && this.selectedEnterprise;
      return !disabled;
    },
    profiles() {
      return this.modal.payload.profiles;
    },
    enterprises() {
      return this.modal.payload.enterprises;
    },
    isLimitedAccessEnabled() {
      return this.config.ENABLE_LIMITED_ACCESS;
    },
    /** Besoin de convertir les caractères AZERTY envoyés par un lecteur de badge QWERTY mal configuré */
    convertedBadge: {
      get() {
        return this.formatBadgeInput(this.formData.badge);
      },
      set(v) {
        this.formData.badge = this.formatBadgeInput(v);
      }
    },

    ...mapGetters('ui', ['config'])
  },
  mounted() {
    if (this.oldValues) {
      this.type = 0;
      this.formData.firstname = this.oldValues.firstname;
      this.formData.lastname = this.oldValues.lastname;
      this.formData.badge = this.oldValues.badge;
      this.formData.isLimitedAccess = this.oldValues.isLimitedAccess;
      this.selectedProfile = get(find(this.profiles, { name: this.oldValues.profile }), 'id', null);
      this.selectedEnterprise = get(find(this.enterprises, { name: this.oldValues.enterprise }), 'id', null);
    } else if (this.isLimitedAccessEnabled) {
      this.formData.isLimitedAccess = 0;
    }
  },
  beforeDestroy() {
    this.close();
  },
  methods: {
    validateString(value) {
      return !!value.match(/^[a-z0-9 `!@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?~àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]*$/i) && value.length > 0 && value.length < 51;
    },
    close() {
      this.$emit('close');
    },
    async submit() {
      if (this.modal.payload.submit) {
        await this.modal.payload.submit({
          firstname: this.formData.firstname,
          lastname: this.formData.lastname,
          badge: this.convertedBadge,
          isLimitedAccess: this.formData.isLimitedAccess ? this.formData.isLimitedAccess : 0,
          agentProfileId: this.selectedProfile,
          enterpriseId: this.selectedEnterprise
        });

        this.close();
      }
    }
  }
};
</script>

<style lang="scss">
.modals-container {
  border: 1px solid #ffffff;
}

.change-item-modal {
  &--header {
    text-align: center;
  }

  .theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
    background-color: rgb(56, 187, 0, 0.6) !important;
    border-color: rgb(56, 187, 0, 0.6) !important;
  }

  .theme--light.v-text-field--filled > .v-input__control > .v-input__slot {
    background-color: white !important;
  }

  &--content {
    text-align: center;
    margin-top: 10px;
    height: auto !important;
    border: 1px solid #e4e6e8;
    border-radius: 10px;

    &-label {
      font-size: 18px;
      margin-top: 66px;
      text-align: center;
    }

    &-input {
      margin-top: 69px;

      &-form {
        width: 70%;

        .v-input__slot {
          padding-left: 0 !important;
        }
      }

      label {
        font-size: 12px;
        font-weight: 400;
      }

      input {
        font-weight: 700;
      }
    }
  }

  &--buttons {
    margin-top: 10px;
    margin-bottom: 20px;
    width: 100%;

    button {
      padding: 10px 0 10px;
      height: 38px;
      width: 121px;
      border-radius: 10px;
      font-size: 14px;
      border: 1px solid #e2e2ea;
      font-weight: 600;
      font-size: 14px;
      text-transform: none;
    }

    &-cancel {
      margin-right: 8px;
      color: #696974 !important;
    }
  }
}
</style>

import moment from 'moment';

import { STATE_VALUE } from '@/common/constants';

export const formatValues = {
  methods: {

    formatTime(date) {
      return moment(date)
        .utcOffset(60)
        .format('DD/MM/YYYY');
    },

    formatDateHourMinute(date) {
      return moment(date).format('DD/MM/YYYY HH:mm');
    },

    /**
     * Utilisé pour convertir les badges scannés par un lecteur QWERTY sur un PC en AZERTY :
     * - Converti les caractères suivants par leur équivalent sur un clavier QWERTY : &é"'(-è_çàq
     * - Passe la châine en majuscule
     * */
    formatBadgeInput(str) {
      return str
        .replace('&', '1')
        .replace('é', '2')
        .replace('"', '3')
        .replace("'", '4')
        .replace('(', '5')
        .replace('-', '6')
        .replace('è', '7')
        .replace('_', '8')
        .replace('ç', '9')
        .replace('à', '0')
        .replace('q', 'a') // Q->A pour les codex Hexadécimaux. Les 5 autres lettres (BCDEF) sont identiques sur QWERTY ou AZERTY
        .toUpperCase();
    },
    setState(stateCode) {
      return STATE_VALUE[stateCode];
    },
  }
};
